.muted {
  color: $body-font-color;
  opacity: 0.3;
}


header {
  @extend .text-center;
  padding: $global-padding * 2;
  background-color: #21252b;
  border-bottom: 1px #17191f solid;

  a {
    color: inherit;
    &:hover, &:visited {
      text-decoration: underline;
      color: inherit;
    }
  }
}

main {
  padding-top: $global-padding * 4;
  padding-bottom: $global-padding * 4;
}

.row {
  > .column {
    @extend .large-6, .medium-8, .small-12, .columns, .small-centered;
  }
}

footer {
  padding: $global-padding * 2 0;
  background-color: #21252b;
  border-top: 1px #17191f solid;
  p {
    font-size: 0.8rem;
  }
}

h2 {
  color: $secondary-color;
  padding-top: 4rem;
  &:before {
    content: "#";
  }
  &:first-child {
    padding-top: 0;
  }
}

h3 {
  padding-top: 3rem;
}

textarea {
  height: 5rem;
}
